
interface IPricigPlan {
    name: string;
    type: string;
    price: number;
    limit: number;
    impresions?: number;
    percentage?: number;
    active?: boolean;
    currency?: string;
}

class PricingPlans {
    public CurrencySymbol = '$';
    public Plans: IPricigPlan[] = [
        {
            name: 'Basic plan',
            type: 'tier1',
            price: 14.92,
            limit: 30000,
        },
        {
            name: 'Level 1',
            type: 'tier2',
            price: 29.92,
            limit: 60000,
        },
        {
            name: 'Level 2',
            type: 'tier3',
            price: 54.92,
            limit: 100000,
        },
        {
            name: 'Level 3',
            type: 'tier4',
            price: 109.92,
            limit: 250000,
        },
        {
            name: 'Level 4',
            type: 'tier5',
            price: 192.92,
            limit: 500000,
        },
        {
            name: 'The Big Guns',
            type: 'tier6',
            price: 249.92,
            limit: 750000,
        }
    ];

    public Basic: IPricigPlan = {
        name: 'Basic',
        type: 'basic',
        price: 13.33,
        limit: 0,
        currency: 'EUR'
    };

    public BasicUSD: IPricigPlan = {
        name: 'BasicUSD',
        type: 'basicUSD',
        price: 14.92,
        limit: 0,
        currency: 'USD'
    };

    public Tier1: IPricigPlan = {
        name: 'Tier 1',
        type: 'tier1',
        price: 14.92,
        limit: 30000,
    };
    public Tier2: IPricigPlan = {
        name: 'Tier 2',
        type: 'tier2',
        price: 29.92,
        limit: 60000,
    };
    public Tier3: IPricigPlan = {
        name: 'Tier 3',
        type: 'tier3',
        price: 54.92,
        limit: 100000,
    };
    public Tier4: IPricigPlan = {
        name: 'Tier 4',
        type: 'tier4',
        price: 109.92,
        limit: 250000,
    };
    public Tier5: IPricigPlan = {
        name: 'Tier 5',
        type: 'tier5',
        price: 192.92,
        limit: 500000,
    };
    public Tier6: IPricigPlan = {
        name: 'Tier 6',
        type: 'tier6',
        price: 249.92,
        limit: 750000,
    };
}

export { IPricigPlan, PricingPlans }