enum UrlFilterOperator {
    Does = 'Does',
    DoesNot = 'DoesNot',
    GeoLocation = 'GeoLocation'
}

enum UrlFilterRule {
    Match = 'Match',
    Contains = 'Contains',
    BeginWith = 'BeginWith',
    EndWith = 'EndWith',
}

enum IntegrationType {
    None = 'None',
    ActiveCampaign = 'ActiveCampaign',
    AWeber = 'AWeber',
    Attentive = 'Attentive',
    Brevo = 'Brevo',
    CampaignMonitor = 'CampaignMonitor',
    ConvertKit = 'ConvertKit',
    Drip = 'Drip',
    EmailListVerify = 'EmailListVerify',
    Emotive = 'Emotive',
    Firepush = 'Firepush',
    GetResponse = 'GetResponse',
    Kickbox = 'Kickbox',
    Klaviyo = 'Klaviyo',
    MailChimp = 'MailChimp',
    MailerLite = 'MailerLite',
    Mailjet = 'Mailjet',
    Mobiniti = 'Mobiniti',
    NewsMAN = 'NewsMAN',
    Omnisend = 'Omnisend',
    OnVoard = 'OnVoard',
    Postscript = 'Postscript',
    PushOwl = 'PushOwl',
    RecartSMS = 'RecartSMS',
    Sendlane = 'Sendlane',    
    SmartrMail = 'SmartrMail',
    SMSBump = 'SMSBump',
    Weglot = 'Weglot'
}

enum ScheduleType {
    Ongoing = 'Ongoing',
    Fixed = 'Fixed',
}

enum AppType {
    Wheelio = 'Wheelio',
    Wheelio2 = 'Wheelio2',
    Rollie = 'Rollie',
    Scratchy = 'Scratchy'
}

enum CustomBarType {
    DiscountCodeBar = 'DiscountCodeBar',
    ProgressBar = 'ProgressBar',
}

enum ShopType {
    Shopify = 'Shopify',
    Standalone = 'Standalone'
}

export { UrlFilterOperator, UrlFilterRule,  IntegrationType, ScheduleType, AppType, CustomBarType, ShopType };
