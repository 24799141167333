
// before you will make any import
Component.registerHooks([
    'beforeRouteEnter',
    'beforeRouteLeave',
    'beforeRouteUpdate',
]);

import Component from 'vue-class-component';
import { Vue, Watch } from 'vue-property-decorator';
import { ScheduleType, AppType, ShopType } from '../helpers/enums';
import { Helpers} from '../helpers/helpers';
import { ICampaign, UrlFilter, Trigger } from '../models/Campaign';
import { App, Color, Coupon, Theme, CustomField, CustomBar, Images } from '../models/App';
import * as moment from 'moment';
import { Route } from 'vue-router';

import { BaseClass } from '../models/baseClass';
import AppGeneral from '@/components/AppGeneral.vue';
import AppTexts from '@/components/AppTexts.vue';
import AppColors from '@/components/AppColors.vue';
import AppTriggers from '@/components/AppTriggers.vue';
import AppSlices from '@/components/AppSlices.vue';
import AppUrlFiltering from '@/components/AppUrlFiltering.vue';
import AppGDPR from '@/components/AppGDPR.vue';
import AppIntegrations from '@/components/AppIntegrations.vue';
import WheelioTemplate from '@/components/WheelioTemplate.vue';
import Wheelio2Template from '@/components/Wheelio2Template.vue';
import RollieTemplate from '@/components/RollieTemplate.vue';
import ScratchyTemplate from '@/components/ScratchyTemplate.vue';
import EmailCollects from '@/components/EmailCollects.vue';
import AppStatistics from '@/components/AppStatistics.vue';
import AppCustomFields from '@/components/AppCustomFields.vue';
import AppCustomBars from '@/components/AppCustomBars.vue';
import AppEmailSegmentation from '@/components/AppEmailSegmentation.vue';
// import AppTOS from '@/components/AppTOS.vue';

import UnsavedChangesDialog from '@/components/dialogs/UnsavedChangesDialog.vue';
import { defaultFormatUtc } from 'moment';
import { Trans } from '../plugins/translation';
import { EmailSegmentation } from '../models/EmailSegmentation';
import { PhoneSimulator} from '../components/simulators/PhoneSimulator';


@Component({
    // @ts-ignore
    components: {
        AppGeneral,
        AppTexts,
        AppColors,
        AppTriggers,
        AppSlices,
        AppUrlFiltering,
        AppGDPR,
        AppIntegrations,
        EmailCollects,
        WheelioTemplate,
        Wheelio2Template,
        RollieTemplate,
        ScratchyTemplate,
        AppStatistics,
        AppCustomFields,
        AppCustomBars,
        AppEmailSegmentation,
        UnsavedChangesDialog,
        PhoneSimulator
  },
})
export default class Campaign extends BaseClass<ICampaign> {
    private tab: number = 0;
    private actionResetCookieLoading: boolean = false;
    private e1: number = 0;
    private on: object = {};
    private startDate: string = new Date().toISOString().substr(0, 10);
    private endDate: string = new Date().toISOString().substr(0, 10);
    private conversionRate: number = 0;
    private domainUrl: string = '';
    private showPreview: boolean = true;
    private mainviewCols: number = 6;
    private stickyElement;
    private stickyElementOffset;
    private stickyLocation: number = 0;
    private isSticky: boolean = false;
    private toolbarSticky: boolean = false;
    private mainview;
    private mainContent = document.getElementById('main-content');
    private mobilePreview: number = 0;
    private campaign: ICampaign = {
        id: '',
        name: '',
        active: false,
        scheduleType: ScheduleType.Ongoing,
        allowEmailDuplicates: false,
        allowIpDuplicates: false,
        integrationsEnabled: false,
        injectCoupon: false,
        timeZone: 'Europe/London'
    };
    private themeImages: Images = { background: '', top: '', bottom: '', pulltab: '', wheel: '' };

    private integrationsMailEnabled: boolean = false;
    private integrationsSMSEnabled: boolean = false;
    private phoneType: string = 'iphone12';
    private scale: number = 1;
    private swapView: number = 0;
    private CDN_URL: string = 'https://cdn.wheelio-app.com';

    @Watch('campaign', { deep: true })
    private onPropertyChanged(value: ICampaign) {
        this.hasChanges(value);
    }

    /* Methods */
    private created() {
        return this.getCampaign();
    }

    private mounted () {
        window.addEventListener('scroll', this.handleScroll);
    }

    private beforeDestroy () {
        window.removeEventListener('scroll', this.handleScroll);
    }

    private handleScroll() {
        this.stickyElement = document.getElementById('stickyElement');
        if(this.stickyElement != null) {
            this.stickyElementOffset = this.stickyElement.offsetTop;
            if (window.pageYOffset > this.stickyElementOffset + this.stickyElement.clientHeight/4) {
                if(this.isSticky === false && this.mainContent !== null) {
                    const parent = document.getElementById('stickyParent');
                    this.stickyElement.classList.add('sticky');
                    this.stickyElement!.setAttribute('style','width:'+ (parent!.clientWidth-24) + 'px; transform: translateY(60px');
                    this.stickyLocation = window.pageYOffset;
                    this.isSticky = true;
                }
            }
            if (window.pageYOffset < this.stickyLocation) {
                this.stickyElement.classList.remove('sticky');
                this.stickyElement!.setAttribute('style','transform: translateY(0);');
                this.isSticky = false;
            }
        }

        this.mainview = document.getElementById('mainview');
        if(this.mainview != null) {
            if (window.pageYOffset > this.mainview.offsetTop) {
                if(this.toolbarSticky === false) {
                    this.toolbarSticky = true;
                }
            }
            else {
                if(this.toolbarSticky === true) {
                    this.toolbarSticky = false;
                }
            }
        }
    }

    private async getCampaign() {
        this.loading = true;
        try {
            let apiUrl = `/api/campaign/getcampaign/${this.$route.params.id}`;
            if (this.$route.params.shopId !== undefined) {
                apiUrl += `/${this.$route.params.shopId}`;
            }
            const response = await this.axios.get(apiUrl);
            this.campaign = JSON.parse(JSON.stringify(response.data.data));
            // fallback if campaign has no pointerColor or buttonFontColor
            // @ts-ignore
            this.campaign.app.colors.forEach(el => {
                if(el.pointerColor === null) {
                    el.pointerColor = { hex: '#ffa3bf' };
                }
                if(el.buttonFontColor === null) {
                    el.buttonFontColor = { hex: '#FFFFFF' };
                }
            });

            // fallback if campaign has no desktopUserIntentDelay
            // @ts-ignore
            if(this.campaign.triggers.desktopUserIntentDelay === null) {
                // @ts-ignore
                this.campaign.triggers.desktopUserIntentDelay = 0;
            }
            // fallback if campaign has no persist setting
            // @ts-ignore
            if(this.campaign.triggers.mobilePersist === null) {
                // @ts-ignore
                this.campaign.triggers.mobilePersist = false;
            }
            // @ts-ignore
            if(this.campaign.triggers.desktopPersist === null) {
                // @ts-ignore
                this.campaign.triggers.desktopPersist = false;
            }
            // @ts-ignore
            if(this.campaign.shop.currency === null) {
                // @ts-ignore
                this.campaign.shop.currency = 'USD';
            }
            this.setCacheObject(JSON.parse(JSON.stringify(this.campaign)));

            // @ts-ignore
            this.app = this.campaign.app;
            // @ts-ignore
            const selectedTheme = this.campaign.app.themes.findWith('selected', true);
            // @ts-ignore
            this.themeImages = JSON.parse(JSON.stringify(selectedTheme.images));

            // @ts-ignore
            this.domainUrl = this.campaign.shop.domain;
            // @ts-ignore
            if (this.campaign.shop.type === ShopType.Shopify) {
                // @ts-ignore
                this.domainUrl = this.campaign.shop.customDomain;
            }
            if (!this.domainUrl.endsWith('/')) {
                this.domainUrl = `${this.domainUrl}/`;
            }
            if (/(http(s?)):\/\//gi.test(this.domainUrl) === false) {
                   this.domainUrl = `https://${this.domainUrl}`;
            }
            this.hasChanged = false;
        } catch (error: any) {
            this.errorHandler.Server(error);
        } finally {
            this.loading = false;
        }
    }

    private async saveCampaign() {
        // @ts-ignore
        if (this.$refs.appGeneral.$refs.form.validate()) {
            this.actionLoading = true;
            try {
                let apiUrl = `/api/campaign/updateCampaign/${this.campaign.id}`;
                if (this.$route.params.shopId !== undefined) {
                    apiUrl += `/${this.$route.params.shopId}`;
                }

                // @ts-ignore
                this.campaign.app.themes.findWith('selected', true).images = JSON.parse(JSON.stringify(this.themeImages))
                await this.axios.put(apiUrl, this.campaign);

                this.hasChanged = false;
                this.setCacheObject(JSON.parse(JSON.stringify(this.campaign)));
            } catch (error: any) {
                this.errorHandler.Server(error);
            } finally {
                this.actionLoading = false;
            }
        }
    }

    private cancelChanges() {
        this.campaign = JSON.parse(JSON.stringify(this.getCacheObject()));
        if (this.$refs.appColors) {
            // @ts-ignore
            this.$refs.appColors.updateSelection(this.campaign.app);
        }
        if (this.$refs.appGeneral) {
            // @ts-ignore
            this.$refs.appGeneral.updateSelection(this.campaign);
        }
        if (this.$refs.appFilters) {
            // @ts-ignore
            this.$refs.appFilters.updateSelection(this.campaign.urlFilters);
        }
        this.hasChanged = false;
    }

    private beforeRouteLeave(to: Route, _from: Route, next: any) {
        if (this.hasChanged) {
            next(false);
            this.newPath = to.name;
            this.showCancelChangesDialog = true;
        } else {
            next();
        }
    }

    @Watch('cancelChangesDialogResponse', { deep: true })
    private onResponseChanged(value: boolean) {
        if (value === true) {
            this.hasChanged = false;
            this.$router.push({
                name: this.newPath,
            });
        }
    }

    private getUserLang() {
        return Trans.getUserLang().lang;
    }

    private preview() {
        const url = `${this.domainUrl}?wloprvw=1&cId=${this.campaign.id}`;
        window.open(url, '_blank');
    }

    private checkSplitView(){
        if(this.tab > 6) {
            this.mainviewCols = 12;
            this.showPreview = false;
        } else {
            this.mainviewCols = 6;
            this.showPreview = true;
        }

        // show "Thank you" page on if Shop Login enabled
        // if (this.tab=== 6 && this.campaign.messengerSettings?.shopLogin.enabled) {
        //    this.swapView = 1;
        // }
    }
    get mobileWidth() {
        if (this.$vuetify.breakpoint.md) {
            return '420px';
        }
        return '100%';
    }

    get getFilterCountries() {
        // @ts-ignore
        const countries = JSON.parse(JSON.stringify(this.campaign.countries));
        countries.move(countries.findIndex((c) => c === 'India'), 0);
        countries.move(countries.findIndex((c) => c === 'Chile'), 0);
        countries.move(countries.findIndex((c) => c === 'Mexico'), 0);
        countries.move(countries.findIndex((c) => c === 'Italy'), 0);
        countries.move(countries.findIndex((c) => c === 'Germany'), 0);
        countries.move(countries.findIndex((c) => c === 'France'), 0);
        countries.move(countries.findIndex((c) => c === 'Australia'), 0);
        countries.move(countries.findIndex((c) => c === 'United Kingdom'), 0);
        countries.move(countries.findIndex((c) => c === 'Canada'), 0);
        countries.move(countries.findIndex((c) => c === 'United States'), 0);
        return countries;
    }

    get isSuperAdmin() {
        const username = sessionStorage.getItem('username') || '';
        // @ts-ignore
        if (process.env.VUE_APP_SUPER_ADMINS.includes(username)) {
            return true;
        }
        return false;
    }
}
