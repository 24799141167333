import { render, staticRenderFns } from "./Wheelio2Template.vue?vue&type=template&id=50ae0330"
import script from "./Wheelio2Template.vue?vue&type=script&lang=ts"
export * from "./Wheelio2Template.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports